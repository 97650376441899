<template>
  <div>
    <div class="create_banner">
      <h1 style="margin: 5px; font-size: 40px">CokeJogo深度二次开发</h1>
      <h1 style="margin: 5px; font-size: 25px">出海接口游戏&短视频爆款产品</h1>
    </div>
    <div style="text-align: center; background-color: white">
      <h1 style="margin: 0; padding: 20px">游戏区域</h1>
      <el-row :gutter="30" style="margin: 0 150px; display: flex">
        <el-col
          :span="8"
          :offset="0"
          style="display: flex; justify-content: center"
        >
          <div
            style="
              border: 0.5px #909399 solid;
              border-radius: 20px;
              width: 300px;
            "
          >
            <img
              src="@/assets/created/india.png"
              alt=""
              style="height: 180px; width: 300px"
            />
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0 30px 10px 30px;
              "
            >
              <p style="margin: 0; font-size: 20px; margin-top: 5px">印度</p>
              <img
                src="@/assets/created/Flag_of_India.png"
                alt=""
                style="height: 40px"
              />
            </div>
          </div>
        </el-col>
        <el-col
          :span="8"
          :offset="0"
          style="display: flex; justify-content: center"
        >
          <div
            style="
              border: 0.5px #909399 solid;
              border-radius: 20px;
              width: 300px;
            "
          >
            <img
              src="@/assets/created/brazil.png"
              alt=""
              style="height: 180px; width: 300px"
            />
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0 30px 10px 30px;
              "
            >
              <p style="margin: 0; font-size: 20px; margin-top: 5px">巴西</p>
              <img
                src="@/assets/created/Flag_of_Brazil.png"
                alt=""
                style="height: 40px"
              />
            </div>
          </div>
        </el-col>
        <el-col
          :span="8"
          :offset="0"
          style="display: flex; justify-content: center"
        >
          <div
            style="
              border: 0.5px #909399 solid;
              border-radius: 20px;
              width: 300px;
            "
          >
            <img
              src="@/assets/created/Indonesia.png"
              alt=""
              style="height: 180px; width: 300px"
            />
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0 30px 10px 30px;
              "
            >
              <p style="margin: 0; font-size: 20px; margin-top: 5px">印尼</p>
              <img
                src="@/assets/created/Flag_of_Indonesia.png"
                alt=""
                style="height: 40px"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <h1 style="margin: 0; padding: 20px; margin-top: 40px">游戏语言</h1>
      <el-row :gutter="30" style="margin: 0 150px">
        <el-col
          :span="8"
          :offset="0"
          style="display: flex; justify-content: center"
        >
          <div
            style="
              display: flex;
              border: 0.5px #909399 solid;
              width: 300px;
              justify-content: space-around;
              border-radius: 10px;
              padding: 10px 0;
            "
          >
            <p style="font-size: 25px; line-height: 10px; color: #666666">
              英语
            </p>
            <img
              src="@/assets/created/lang_english.png"
              alt=""
              style="width: 100px; height: 60px"
            />
          </div>
        </el-col>
        <el-col
          :span="8"
          :offset="0"
          style="display: flex; justify-content: center"
        >
          <div
            style="
              display: flex;
              border: 0.5px #909399 solid;
              width: 300px;
              justify-content: space-around;
              border-radius: 10px;
              padding: 10px 0;
            "
          >
            <p style="font-size: 25px; line-height: 10px; color: #666666">
              葡萄牙语
            </p>
            <img
              src="@/assets/created/lang_portuguese.png"
              alt=""
              style="width: 100px; height: 60px"
            />
          </div>
        </el-col>
        <el-col
          :span="8"
          :offset="0"
          style="display: flex; justify-content: center"
        >
          <div
            style="
              display: flex;
              border: 0.5px #909399 solid;
              width: 300px;
              justify-content: space-around;
              border-radius: 10px;
              padding: 10px 0;
            "
          >
            <p style="font-size: 25px; line-height: 10px; color: #666666">
              印地语
            </p>
            <img
              src="@/assets/created/lang_india.png"
              alt=""
              style="width: 100px; height: 60px"
            />
          </div>
        </el-col>
      </el-row>
      <h1 style="margin: 0; padding: 20px; margin-top: 40px">部署方案</h1>
      <div style="display: flex; justify-content: center">
        <h1
          class="create_server"
          :class="{ selected: selectedOption === 1 }"
          @click="selectOption(1)"
        >
          从现有服务器
        </h1>
        <h1
          class="create_server"
          :class="{ selected: selectedOption === 2 }"
          @click="selectOption(2)"
          style="margin-left: 100px"
        >
          云服务部署方式
        </h1>
      </div>
      <!-- 从现有服务器 -->
      <div
        v-show="selectedOption === 1"
        style="
          text-align: left;
          margin: 0 180px;
          border: 0.5px #666666 solid;
          border-radius: 20px;
          padding: 50px;
        "
      >
        <p style="font-size: 30px; margin: 10px">SSH信息</p>
        <el-row :gutter="20" style="text-align: center">
          <el-col :span="12" :offset="0">
            <div>
              <span style="font-size: 20px">服务器IP：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                "
              />
            </div>
            <div style="margin-top: 30px">
              <span style="font-size: 20px">端口号：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                  margin-left: 15px;
                "
              />
            </div>
          </el-col>
          <el-col :span="12" :offset="0">
            <div>
              <span style="font-size: 20px">用户名：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                "
              />
            </div>
            <div style="margin-top: 30px">
              <span style="font-size: 20px">密码：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                  margin-left: 15px;
                "
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 云服务部署方式 -->
      <div
        v-show="selectedOption === 2"
        style="
          text-align: left;
          margin: 0 180px;
          border: 0.5px #666666 solid;
          border-radius: 20px;
          padding: 50px;
        "
      >
        <p style="font-size: 30px">选择购买服务时长</p>
        <el-row :gutter="20">
          <el-col :span="6" :offset="0">
            <div
              style="
                display: flex;
                justify-content: center;
                background-color: #ffeae6;
                border-radius: 10px;
              "
            >
              <i
                class="el-icon-caret-right"
                style="color: #1f62b5; line-height: 85px; font-size: 20px"
              ></i>
              <p style="font-size: 25px; margin-left: 30px">1个月</p>
            </div>
          </el-col>
          <el-col :span="6" :offset="0">
            <div
              style="
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffeae6;
                border-radius: 10px;
              "
            >
              <i
                class="el-icon-caret-right"
                style="color: #1f62b5; line-height: 85px; font-size: 20px"
              ></i>
              <p style="font-size: 25px; margin-left: 10px">3个月</p>

              <div
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 0;
                  height: 0;
                  border-top: 50px solid red;
                  border-left: 50px solid transparent;
                  border-radius: 0 10px 0 0;
                "
              >
                <span
                  style="
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    left: -30px;
                    transform: rotate(45deg);
                    color: white;
                    font-size: 14px;
                  "
                  >9折</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="6" :offset="0">
            <div
              style="
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #eaf9f0;
                border-radius: 10px;
              "
            >
              <i
                class="el-icon-caret-right"
                style="color: #1f62b5; line-height: 85px; font-size: 20px"
              ></i>
              <p style="font-size: 25px; margin-left: 10px">半年</p>

              <div
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 0;
                  height: 0;
                  border-top: 50px solid red;
                  border-left: 50px solid transparent;
                  border-radius: 0 10px 0 0;
                "
              >
                <span
                  style="
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    left: -30px;
                    transform: rotate(45deg);
                    color: white;
                    font-size: 14px;
                  "
                  >8折</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="6" :offset="0">
            <div
              style="
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #e6f1ff;
                border-radius: 10px;
              "
            >
              <i
                class="el-icon-caret-right"
                style="color: #1f62b5; line-height: 85px; font-size: 20px"
              ></i>
              <p style="font-size: 25px; margin-left: 10px">1年</p>

              <div
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 0;
                  height: 0;
                  border-top: 50px solid red;
                  border-left: 50px solid transparent;
                  border-radius: 0 10px 0 0;
                "
              >
                <span
                  style="
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    left: -30px;
                    transform: rotate(45deg);
                    color: white;
                    font-size: 14px;
                  "
                  >6折</span
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 价格计算 -->
      <div
        style="
          text-align: left;
          margin: 60px 180px 0 180px;
          padding-bottom: 100px;
        "
      >
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <p style="font-size: 30px">价格计算：</p>
          </el-col>
          <el-col :span="12" :offset="0" style="text-align: right">
            <el-select
              v-model="value"
              placeholder="请选择优惠卷"
              style="margin-top: 30px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              总计价格：30,000CNY
            </p>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              优惠金额：1,000CNY
            </p>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              结算价格：29,000CNY
            </p>
            <button
              style="
                font-size: 20px;
                background-color: #1f62b5;
                color: white;
                border: 0;
                padding: 10px 30px;
                border-radius: 10px;
              "
            >
              立即支付
            </button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: 1,
      serverIp: "",
      serverPort: "",
      username: "",
      password: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option; // 切换选中的元素
    },
  },
  created() {},
};
</script>

<style>
.create_banner {
  position: relative;
  width: 100%;
  height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 0;
  overflow: hidden; /* 确保伪元素不溢出 */
}

.create_banner::before {
  content: "";
  background-image: url("@/assets/created/banner.png");
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(0.8); /* 只对背景生效 */
}

.create_banner h1 {
  color: white;
  text-shadow: -2px -2px 0 #304978, /* 左上 */ 2px -2px 0 #304978,
    /* 右上 */ -2px 2px 0 #304978, /* 左下 */ 2px 2px 0 #304978; /* 右下 */
}

.create_server {
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 20px;
  transition: all 0.2s ease;
}

.selected {
  background-color: #a8d0e6 !important; /* 选中时的背景颜色 */
}
/* 
@media screen and (min-width: 1920px) {
  .mainArea {
    margin: 0 350px;
  }
} */
</style>
