<template>
  <div>
    <!-- 我的邀请注册地址 -->
    <div
      style="
        display: flex;
        background-color: white;
        justify-content: center;
        height: 40px;
        align-items: center;
      "
    >
      <p style="font-weight: bold">我的邀请注册地址</p>
      <el-input
        v-model="inviteUrl"
        style="width: 500px; margin-left: 10px"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="copyText()"
        >复制</el-button
      >
    </div>
    <div style="margin: 20px">
      <el-row :gutter="20" style="text-align: center">
        <el-col :span="8" :offset="0">
          <el-card>
            <h2>我的直接客户数量</h2>
            <h2>{{ data.direct_client_count }}</h2>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card>
            <h2>我的间接客户数量</h2>
            <h2>{{ data.indirect_client_count }}</h2>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card>
            <h2>今日新增间接客户</h2>
            <h2>{{ data.today_new_client_count }}</h2>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="text-align: center; margin-top: 20px">
        <el-col :span="8" :offset="0">
          <el-card>
            <h2>今日佣金</h2>
            <h2>{{ data.today_commission }}</h2>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card>
            <h2>当月新增直接客户</h2>
            <h2>{{ data.monthly_new_direct_client_count }}</h2>
          </el-card>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-card>
            <h2>当月佣金</h2>
            <h2>{{ data.monthly_commission }}</h2>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getAgentUserInfo } from "@/api/api";
export default {
  data() {
    return {
      inviteUrl: "",
      url: "https://www.jisuqipai.online/#/reg?uid=",
      uid: "",
      data: [],
    };
  },
  methods: {
    getAgentUserInfos() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = this.uid;
      getAgentUserInfo(token, uid)
        .then((res) => {
          if (res.data.code === 200) {
            this.data = res.data.data;
            loading.close();
          } else {
            this.$message.error("数据获取失败！" + res.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error("数据获取失败！" + res.msg);
          loading.close();
        });
    },
    copyText() {
      const url = this.inviteUrl;
      navigator.clipboard.writeText(url);
      this.$message.success("复制成功");
    },
  },
  created() {
    this.uid = localStorage.getItem("uid");
    this.inviteUrl = this.url + this.uid;
    const agent = localStorage.getItem("Agent");
    if (agent === "true") {
      this.getAgentUserInfos();
    }
  },
};
</script>

<style></style>
