import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jisuqipai.online/",
});

// 登录接口
export const Login = (account, password) => {
  return axiosInstance.post("/api/user/login", {
    account: account,
    password: password,
  });
};

// 注册接口
export const Register = (username, password, email, captcha) => {
  return axiosInstance.post("/api/user/register", {
    username: username,
    password: password,
    email: email,
    captcha: captcha,
  });
};

// usdt支付接口
export const usdtPay = (amount, token) => {
  return axiosInstance.post(
    "/api/payment/usdt",
    {
      amount: amount,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取文章列表
export const getArticles = () => {
  return axiosInstance.get("/api/index/articles");
};

// 获取对应id文章信息
export const getArticleDetails = (id) => {
  return axiosInstance.post("/api/index/articleDetails", {
    id: id,
  });
};

// 获取邮箱验证码
export const getMailCaptcha = (email, event) => {
  return axiosInstance.post("/api/ems/send", {
    email: email,
    event: event,
  });
};

// 通过邮箱找回验证码
export const resetpwd = (email, newpassword, captcha) => {
  return axiosInstance.post("/api/user/resetpwd", {
    email: email,
    newpassword: newpassword,
    captcha: captcha,
  });
};

// YonoGames上分体验
export const YonoUpscore = (uid, coin, token) => {
  return axiosInstance.post(
    "/api/demo/YonoUpscore",
    {
      uid: uid,
      coin: coin,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 日活月活访问统计
export const statistics = () => {
  return axiosInstance.post("/api/index/statistics");
};

// 代理登录接口
export const AgentLogin = (account, password) => {
  return axiosInstance.post("/api/agent/login", {
    account: account,
    password: password,
  });
};

// 代理创建用户
export const createAgent = (token, username) => {
  return axiosInstance.post(
    "/api/agent/createAgent",
    {
      username: username,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 代理重置密码
export const resetPwd = (token, username, oldPassword, newPassword) => {
  return axiosInstance.post(
    "/api/agent/resetPwd",
    {
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 通过代理链接创建用户
export const registerForUid = (
  username,
  password,
  email,
  captcha,
  inviteid
) => {
  return axiosInstance.post("/api/user/registerForUid", {
    username: username,
    password: password,
    email: email,
    captcha: captcha,
    inviteid: inviteid,
  });
};

// 获取当前代理用户直接客户数量 间接客户数量 今日新增客户 今日佣金 当月新增直接客户 当月佣金
export const getAgentUserInfo = (token, uid) => {
  return axiosInstance.post(
    "/api/agent/getAgentUserInfo",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 代理获取当前uid下所有客户信息
export const getClientInfo = (token, userid) => {
  return axiosInstance.post(
    "/api/agent/getClientInfo",
    {
      userid: userid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取所有充值记录
export const getrechargelist = (date) => {
  return axiosInstance.post("/api/agent/getrechargelist", {
    date: date,
  });
};

// 代理升级
export const agentUpgrade = (token, uid, level, type) => {
  return axiosInstance.post("/api/agent/agentUpgrade", {
    token: token,
    uid: uid,
    level: level,
    type: type,
  });
};

// 代理获取当前用户的USDT余额
export const getAgentUSDT = (uid) => {
  return axiosInstance.post("/api/agent/getAgentUSDT", {
    uid: uid,
  });
};
