<template>
  <div class="docMain">
    <el-row :gutter="10">
      <el-col :xs="0" :lg="4" :offset="0">
        <el-card>
          <ul style="color: #666666">
            <li>文档目录1</li>
            <li>文档目录2</li>
            <li>文档目录3</li>
          </ul>
        </el-card>
      </el-col>
      <el-col :xs="24" :lg="20" :offset="0">
        <el-card>
          <h1 style="text-align: center">Coke文档标题</h1>
          <p>
            我是文档内容我是文档内容我是文档内容我是文档内容我是文档内容我是文档内容我是文档内容我是文档内容
          </p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {
    const token = localStorage.getItem("token");
    if (!token || token === null) {
      this.$message.success("请先登录");
      this.$router.push("/login");
    }
  },
};
</script>

<style>
.docMain {
  margin: 10px 150px;
}

@media screen and (max-width: 768px) {
  .docMain {
    margin: 10px 20px;
  }
}
</style>
