<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="handleDateChange"
      >
      </el-date-picker>
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="widthDrawDialog = true"
        >佣金提现</el-button
      >
    </div>
    <!-- 数据显示表格 -->
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="uid" label="下级客户uid"> </el-table-column>
      <el-table-column prop="type" label="佣金类型"> </el-table-column>
      <el-table-column prop="rule" label="佣金规则"> </el-table-column>
      <el-table-column prop="total_amount" label="单笔总金额">
      </el-table-column>
      <el-table-column prop="rebate" label="返佣金额"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 佣金提现弹窗 -->
    <el-dialog
      title="佣金提现"
      center
      :visible.sync="widthDrawDialog"
      width="30%"
    >
      <p style="margin: 0">当前可提现的USDT余额为:{{ amount }}</p>
      <p>结算周期为：每日</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="widthDrawDialog = false">取 消</el-button>
        <el-button type="primary" @click="widthDrawDialog = false"
          >提 现</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAgentUSDT, getrechargelist } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      date: [], // 原始的日期
      formattedDate: [], // 格式化后的日期
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      widthDrawDialog: false,
      amount: "",
    };
  },
  methods: {
    handleDateChange(val) {
      // 格式化日期为 YYYY-MM-DD
      this.formattedDate = val.map((date) =>
        new Date(date).toLocaleDateString("zh-CN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
      this.getrechargelists();
    },
    getrechargelists() {
      this.loading = true;
      getrechargelist(this.formattedDate)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.loading = false;
        });
    },
    getAgentUSDTs() {
      const uid = localStorage.getItem("uid");
      getAgentUSDT(uid).then((res) => {
        if (res.data.code === 200) {
          this.amount = res.data.data;
        } else {
          this.$message.error("余额获取失败");
        }
      });
    },
  },
  created() {
    this.getrechargelists();
    this.getAgentUSDTs();
  },
};
</script>

<style></style>
