import { render, staticRenderFns } from "./ServerVue.vue?vue&type=template&id=27bdc350"
import script from "./ServerVue.vue?vue&type=script&lang=js"
export * from "./ServerVue.vue?vue&type=script&lang=js"
import style0 from "./ServerVue.vue?vue&type=style&index=0&id=27bdc350&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports