<template>
  <div>
    <div class="serverMain">
      <i class="fa fa-home"></i>
      <span style="margin-left: 10px">主页</span>
      <i class="fa fa-angle-right" style="margin-left: 10px"></i>
      <span style="margin-left: 10px">海外服务器</span>
    </div>
    <img src="@/assets/server.jpg" alt="" class="bannerImg" />
    <!-- 海外服务器部分 -->
    <div class="serverBox">
      <div style="text-align: left">
        <h3 style="margin: 0 0 0 10px">
          海外服务器
          <span style="float: right; font-weight: 300; margin: 0 10px 0 0"
            >更多>></span
          >
        </h3>
      </div>
      <div style="border-bottom: 1px solid #dcdfe6; margin: 10px 0 0 0" />
      <el-row :gutter="20">
        <el-col
          :xs="12"
          :lg="6"
          :offset="0"
          v-for="item in server"
          :key="item.id"
          style="padding: 20px"
        >
          <!-- <a
            :href="item.url"
            target="_blank"
            style="text-decoration: none; color: inherit; cursor: auto"
          > -->
          <el-row gutter="40">
            <el-col span="6">
              <img
                :src="item.image"
                alt="image"
                style="width: 60px; height: 40px; border-radius: 5px"
              />
            </el-col>
            <el-col span="18" style="line-height: 15px">
              <p
                style="
                  font-weight: bolder;
                  margin-top: 0px;
                  margin-bottom: 10px;
                "
              >
                {{ item.title }}
              </p>
              <span class="buyBtn" @click="$router.push('/server/' + item.id)">
                立即选购
              </span>
            </el-col>
          </el-row>
          <!-- </a> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      server: [
        {
          id: 1,
          image: "https://www.freeimg.cn/i/2024/08/12/66b965cb451ef.png",
          title: "印度服务器",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 2,
          image: "https://www.freeimg.cn/i/2024/08/12/66b965cb0ed99.jpg",
          title: "印尼服务器",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 3,
          image: "https://www.freeimg.cn/i/2024/08/12/66b965cb1874d.png",
          title: "菲律宾服务器",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 4,
          image: "https://www.freeimg.cn/i/2024/08/12/66b965cb0c385.png",
          title: "马来西亚服务器",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 5,
          image: "https://www.freeimg.cn/i/2024/08/12/66b965cac6b98.png",
          title: "越南服务器",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 6,
          image: "https://www.freeimg.cn/i/2024/08/12/66b965cb0b9c7.png",
          title: "巴西服务器",
          //跳转链接
          url: "https://www.baidu.com",
        },
        {
          id: 7,
          image: "https://www.freeimg.cn/i/2024/08/12/66b965cb1e1b5.png",
          title: "美国服务器",
          //跳转链接
          url: "https://www.baidu.com",
        },
      ],
    };
  },
};
</script>

<style>
.buyBtn {
  font-size: 12px;
  color: #abb0bd;
  margin: 5px 0px;
  font-weight: bolder;
  border: 1px #abb0bd solid;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.buyBtn:hover {
  background-color: #abb0bd;
  color: white;
}

.serverMain {
  color: #666666;
  padding: 10px 150px 0px 150px;
}

.bannerImg {
  width: 100%;
  height: 300px;
  padding: 0 150px;
  margin-top: 10px;
}

.serverBox {
  background-color: white;
  margin: 20px 150px 0 150px;
  padding: 15px;
  color: #666666;
}

@media screen and (max-width: 768px) {
  .serverMain {
    display: none;
  }
  .bannerImg {
    padding: 0;
    height: 150px;
  }
  .serverBox {
    margin: 0;
    text-align: right;
  }
}
</style>
