<template>
  <div class="docMain">
    <el-row :gutter="10">
      <el-col :xs="0" :lg="4" :offset="0">
        <t-affix :offset-top="10" :offset-bottom="20" ref="affix">
          <el-card style="color: #666666">
            <!-- <p>序言</p> -->
            <details>
              <summary>1 <a href="#基础">基础</a></summary>
              <details>
                <summary><a href="#环境和部署">环境和部署</a></summary>
                <p><a href="#环境">环境</a></p>
                <details>
                  <summary><a href="#部署">部署</a></summary>
                  <p><a href="#服务器运行">服务器运行</a></p>
                  <p><a href="#客户端源码部分">客户端源码部分</a></p>
                </details>
              </details>
              <p><a href="#项目目录结构">项目目录结构</a></p>
            </details>
            <details>
              <summary>2 <a href="#架构">架构</a></summary>
              <ul>
                <li><a href="#项目运行流程">项目运行流程</a></li>
              </ul>
            </details>
            <details>
              <summary>3 <a href="#接口">接口</a></summary>
              <ul>
                <li><a href="#Lua接口">Lua接口</a></li>
                <li><a href="#Php接口">Php接口</a></li>
              </ul>
            </details>
            <details>
              <summary>4 <a href="#附录">附录</a></summary>
              <ul>
                <li><a href="#支持Https">支持Https</a></li>
                <li><a href="#打包Apk">打包Apk</a></li>
              </ul>
            </details>
          </el-card>
        </t-affix>
      </el-col>
      <el-col :xs="24" :lg="20" :offset="0">
        <el-card>
          <h1 style="text-align: center; font-size: 35px">
            YonoGames海外竖版游戏产品文档
          </h1>
          <h1>1 基础</h1>
          <h3 id="环境与部署">环境与部署</h3>
          <h3 id="环境">环境</h3>
          <p>服务器选择Linux</p>
          <p>安装宝塔Linux面板</p>
          <p>
            进入<a href="https://www.bt.cn/new/index.html">宝塔面板下载地址</a
            >，点击立即免费安装
          </p>
          <img src="@/assets/yonodoc/1.png" alt="" style="width: 100%" />
          <p>下滑找到Linux在线安装，输入服务器ip以及ssh账号密码</p>
          <img src="@/assets/yonodoc/2.png" alt="" style="width: 100%" />
          <p>Php版本选择7.4，随后等待安装即可。</p>
          <p>安装完成会弹出宝塔面板地址以及面板账号密码，注意保存。</p>
          <img src="@/assets/yonodoc/3.png" alt="" style="width: 100%" />
          <p>复制面板地址在浏览器打开</p>
          <img src="@/assets/yonodoc/4.png" alt="" style="width: 100%" />
          <p>输入账号密码进入面板</p>
          <img src="@/assets/yonodoc/5.png" alt="" style="width: 100%" />
          <p>宝塔安装软件，查看一下是否有缺漏</p>
          <img src="@/assets/yonodoc/6.png" alt="" style="width: 100%" />
          <h3 id="部署">部署</h3>
          <h3 id="服务器运行">服务器运行</h3>
          <p>点击“网站”后再点击“添加站点”</p>
          <img src="@/assets/yonodoc/7.png" alt="" style="width: 100%" />
          <p>分别创建api接口，后台系统，客户端的网站。上传对应项目文件。</p>
          <img src="@/assets/yonodoc/8.png" alt="" style="width: 100%" />
          <img src="@/assets/yonodoc/9.png" alt="" style="width: 100%" />
          <p>Indiarummy以及usdt文件需要上传到根目录下data文件夹解压.</p>
          <img src="@/assets/yonodoc/10.png" alt="" style="width: 100%" />
          <p>
            完成以上操作，点击客户端网站可<a href="yono.h5.jisuqipai.online"
              >运行游戏</a
            >
          </p>
          <h3 id="客户端源码部分">客户端源码部分</h3>
          <p>客户端项目打开assets\BalootClient\GlobalVar.js文件</p>
          <p>修改域名以及payV5Url支付接口</p>
          <img src="@/assets/yonodoc/11.png" alt="" style="width: 100%" />
          <img src="@/assets/yonodoc/12.png" alt="" style="width: 100%" />
          <h3 id="项目目录结构">项目目录结构</h3>
          <p>Admin后台系统项目文件，api php接口文件，h5客户端项目文件</p>
          <p>路径/www/wwwroot</p>
          <img src="@/assets/yonodoc/13.png" alt="" style="width: 100%" />
          <p>Lua文件indiarummy，usdt支付文件usdt</p>
          <img src="@/assets/yonodoc/14.png" alt="" style="width: 100%" />
          <h1 id="架构">2 架构</h1>
          <h3 id="项目运行流程">项目运行流程</h3>
          <p>
            打开Cocos creator
            项目构建发布到指定文件夹，压缩后上传宝塔客户端文件夹解压 架构总览
          </p>
          <h1 id="接口">3 接口</h1>
          <h3 id="Lua接口">Lua接口</h3>
          <p>Ws请求</p>
          <p>127.0.0.1:9951 登录以及注册接口 127.0.0.1:9959</p>
          <p>客户端项目以及游戏逻辑接口</p>
          <p>在/data/indiarummy/global/pdefine_msg.lua可以查找9959消息部分</p>
          <img src="@/assets/yonodoc/15.png" alt="" style="width: 100%" />
          <h3 id="Php接口">Php接口</h3>
          <p>http请求</p>
          <p>Api文件夹app/controller路径下</p>
          <img src="@/assets/yonodoc/16.png" alt="" style="width: 100%" />
          <h1 id="附录">4 附录</h1>
          <h3 id="支持Https">支持Https</h3>
          <p>站点修改添加证书</p>
          <img src="@/assets/yonodoc/17.png" alt="" style="width: 100%" />
          <h3 id="打包Apk">打包apk</h3>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {
    const token = localStorage.getItem("token");
    if (!token || token === null) {
      this.$message.success("请先登录");
      this.$router.push("/login");
    }
  },
};
</script>

<style>
p {
  margin: 0;
}

a {
  color: #666666;
  text-decoration: none;
}

.docMain {
  margin: 10px 150px;
}

@media screen and (max-width: 768px) {
  .docMain {
    margin: 10px 20px;
  }
}
</style>
