<template>
  <div>
    <!-- <h1>ServerDetail{{ id }}</h1>
    <h1>{{ text }}</h1> -->
    <!-- 服务器部分 -->
    <div style="margin: 20px 150px 0 150px">
      <el-row style="border-bottom: 0.5px solid #666666">
        <el-col
          :span="6"
          :offset="0"
          style="background-color: #f8f8f8; padding: 20px"
        >
          <p style="margin: 0">{{ text }}一区</p>
          <p style="margin: 0; color: #666666; font-size: 13px">
            BGP国际高速宽带，免备案，低延时
          </p>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="padding: 20px; background-color: white"
        >
          <el-row style="text-align: center">
            <el-col :span="6" :offset="0">
              <p style="margin: 0">2核</p>
              <p style="margin: 0; color: #666666; font-size: 13px">CPU</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">4G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">内存</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50M</p>
              <p style="margin: 0; color: #666666; font-size: 13px">
                BGP国际带宽
              </p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">
                高速SSD硬盘
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="background-color: white; padding: 8px"
        >
          <el-row>
            <el-col :span="8" :offset="0">
              <div
                :style="getStyle(1)"
                @click="select(1)"
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">450</span>元/月
                </p>
                <p style="margin: 0; font-size: 13px">立省50元</p>
              </div>
            </el-col>
            <el-col :span="9" :offset="0">
              <div
                :style="getStyle(2)"
                @click="select(2)"
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">4500</span>元/年
                </p>
                <p style="margin: 0; font-size: 13px">立省1500元</p>
              </div>
            </el-col>
            <el-col :span="7" :offset="0">
              <button class="serverButton" @click="chooseSystemVisible = true">
                立即购买
              </button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row style="border-bottom: 0.5px solid #666666">
        <el-col
          :span="6"
          :offset="0"
          style="background-color: #f8f8f8; padding: 20px"
        >
          <p style="margin: 0">{{ text }}一区</p>
          <p style="margin: 0; color: #666666; font-size: 13px">
            BGP国际高速宽带，免备案，低延时
          </p>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="padding: 20px; background-color: white"
        >
          <el-row style="text-align: center">
            <el-col :span="6" :offset="0">
              <p style="margin: 0">4核</p>
              <p style="margin: 0; color: #666666; font-size: 13px">CPU</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">8G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">内存</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50M</p>
              <p style="margin: 0; color: #666666; font-size: 13px">
                BGP国际带宽
              </p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">
                高速SSD硬盘
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="background-color: white; padding: 8px"
        >
          <el-row>
            <el-col :span="8" :offset="0">
              <div
                @click="selectSend(1)"
                :style="getStyle2(1)"
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">750</span>元/月
                </p>
                <p style="margin: 0; font-size: 13px">立省150元</p>
              </div>
            </el-col>
            <el-col :span="9" :offset="0">
              <div
                @click="selectSend(2)"
                :style="getStyle2(2)"
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 25px">7500</span>元/年
                </p>
                <p style="margin: 0; font-size: 13px">立省3300元</p>
              </div>
            </el-col>
            <el-col :span="7" :offset="0">
              <button class="serverButton" @click="chooseSystemVisible = true">
                立即购买
              </button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="6"
          :offset="0"
          style="background-color: #f8f8f8; padding: 20px"
        >
          <p style="margin: 0">{{ text }}一区</p>
          <p style="margin: 0; color: #666666; font-size: 13px">
            BGP国际高速宽带，免备案，低延时
          </p>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="padding: 20px; background-color: white"
        >
          <el-row style="text-align: center">
            <el-col :span="6" :offset="0">
              <p style="margin: 0">8核</p>
              <p style="margin: 0; color: #666666; font-size: 13px">CPU</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">16G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">内存</p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50M</p>
              <p style="margin: 0; color: #666666; font-size: 13px">
                BGP国际带宽
              </p>
            </el-col>
            <el-col :span="6" :offset="0">
              <p style="margin: 0">50G</p>
              <p style="margin: 0; color: #666666; font-size: 13px">
                高速SSD硬盘
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="9"
          :offset="0"
          style="background-color: white; padding: 8px"
        >
          <el-row>
            <el-col :span="8" :offset="0">
              <div
                @click="selectThred(1)"
                :style="getStyle3(1)"
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 20px">1300</span>元/月
                </p>
                <p style="margin: 0; font-size: 13px">立省400元</p>
              </div>
            </el-col>
            <el-col :span="9" :offset="0">
              <div
                @click="selectThred(2)"
                :style="getStyle3(2)"
                style="
                  text-align: center;
                  color: #cb1414;
                  border: 1px #cb1414 solid;
                  display: inline-block;
                  padding: 5px 30px;
                "
              >
                <p style="margin: 0; font-size: 13px">
                  <span style="font-size: 20px">13000</span>元/年
                </p>
                <p style="margin: 0; font-size: 13px">立省7400元</p>
              </div>
            </el-col>
            <el-col :span="7" :offset="0">
              <button class="serverButton" @click="chooseSystemVisible = true">
                立即购买
              </button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- 服务器配置 -->
    <el-dialog
      title="服务器配置"
      :visible.sync="chooseSystemVisible"
      width="30%"
      center
    >
      <el-form label-position="left" inline label-width="140px">
        <el-form-item label="选择操作系统">
          <el-radio-group v-model="system">
            <el-radio :label="1">Linux - CentOS 7</el-radio>
            <!-- <el-radio :label="2">Windows</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请输入服务器用户名">
          <el-input v-model="serverUsername"></el-input>
        </el-form-item>
        <el-form-item label="请输入服务器密码">
          <el-input v-model="serverPassword"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button class="serverButton" @click="randomPassword()">
          生成随机密码
        </button>
        <button class="serverButton" @click="chooseSystemVisible = false">
          取 消
        </button>
        <button class="serverButton" @click="chooseSystemVisible = false">
          确 定
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: parseInt(this.$route.params.id),
      text: "",
      select1: 1,
      select2: 1,
      select3: 1,
      system: 1,
      chooseSystemVisible: false,
      serverUsername: "",
      serverPassword: "",
    };
  },
  methods: {
    randomPassword() {
      this.serverPassword = this.generateRandomString(8);
    },
    generateRandomString(length) {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    serverArea() {
      switch (this.id) {
        case 1:
          this.text = "印度";
          break;
        case 2:
          this.text = "印尼";
          break;
        case 3:
          this.text = "菲律宾";
          break;
        case 4:
          this.text = "马来西亚";
          break;
        case 5:
          this.text = "越南";
          break;
        case 6:
          this.text = "巴西";
          break;
        case 7:
          this.text = "美国";
          break;
        default:
          this.text = "未知区域"; // 添加一个默认值
      }
    },
    getStyle(col) {
      return {
        backgroundColor: this.select1 === col ? "#ffefef" : "#ffffff",
      };
    },
    getStyle2(col) {
      return {
        backgroundColor: this.select2 === col ? "#ffefef" : "#ffffff",
      };
    },
    getStyle3(col) {
      return {
        backgroundColor: this.select3 === col ? "#ffefef" : "#ffffff",
      };
    },
    select(col) {
      this.select1 = col;
    },
    selectSend(col) {
      this.select2 = col;
    },
    selectThred(col) {
      this.select3 = col;
    },
  },
  created() {
    this.serverArea();
  },
};
</script>

<style>
.serverButton {
  background-color: #cb1414;
  color: white;
  border: 0px;
  padding: 8px 18px;
  border: 1px #cb1414 solid;
  margin-top: 13px;
  transition: all 0.3s ease;
}
.serverButton:hover {
  background-color: white;
  color: #cb1414;
  padding: 8px 18px;
  border: 1px #cb1414 solid;
}

.el-input__inner:focus {
  border-color: #cb1414; /* 聚焦时的边框颜色 */
  box-shadow: 0 0 6px rgba(255, 0, 0, 0.3); /* 聚焦时的阴影颜色 */
}
</style>
