<template>
  <div>
    <!-- 数据显示表格 -->
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="uid" label="客户uid"> </el-table-column>
      <el-table-column prop="level" label="客户等级">
        <template slot-scope="scope">
          <span v-if="scope.row.level">直属客户</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱账号"> </el-table-column>
      <el-table-column prop="successions" label="今日登录次数">
      </el-table-column>
      <el-table-column prop="maxsuccessions" label="当月登录次数">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment";
import { getClientInfo } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
    };
  },
  methods: {
    getClientInfos() {
      this.loading = true;
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      getClientInfo(token, uid)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.loading = false;
        });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getClientInfos();
  },
};
</script>

<style></style>
