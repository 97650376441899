<template>
  <div>
    <!-- banner部分 -->
    <div class="cokeBanner">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0" style="color: white; text-align: center">
          <h1 class="bannerTitle1">Cokejogo出海版</h1>
          <h1 class="bannerTitle2">深度二开优化</h1>
          <h1 class="bannerTitle2">出海接口游戏爆款产品</h1>
          <div style="display: flex; justify-content: center">
            <button class="createGame" @click="$router.push('/createcoke')">
              +创建我的游戏
            </button>
            <button class="createGame" @click="$router.push('/cokedoc')">
              产品文档
            </button>
            <button class="createGame">常见问题</button>
          </div>
        </el-col>
        <el-col :span="12" :offset="0"> &nbsp; </el-col>
      </el-row>
    </div>
    <!-- main -->
    <div class="mainArea">
      <!-- 按钮部分 -->
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :xs="12" :lg="6" :offset="0">
          <a
            href="https://cokejogo.h5.jisuqipai.online"
            target="_blank"
            style="text-decoration: none"
          >
            <div class="cokeBtn">H5试玩</div>
          </a>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="cokeBtn" @click="AndroidToast = true">
            下载Android客户端
          </div>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="cokeBtn" @click="iosToast = true">下载iOS客户端</div>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="cokeBtn" @click="openUpScore()">体验上分</div>
        </el-col>
      </el-row>
      <img
        src="@/assets/coke1.png"
        alt=""
        class="mainImg"
        style="margin-top: 10px"
      />
      <img src="@/assets/coke3.jpg" alt="" class="mainImg" />
      <img src="@/assets/coke4.png" alt="" class="mainImg" />
      <img src="@/assets/coke5.png" alt="" class="mainImg" />
    </div>
    <!-- ios提示 -->
    <el-dialog
      title="提示"
      :visible.sync="iosToast"
      :width="dialogWidth"
      center
    >
      <p>抱歉，演示客户端暂不支持iOS</p>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn"
          style="
            border: 0px;
            font-size: 15px;
            background-color: black;
            color: white;
          "
          @click="iosToast = false"
        >
          取消
        </button>
        <button
          class="btn"
          style="
            border: 0px;
            font-size: 15px;
            margin-left: 20px;
            background-color: #4c55d8;
          "
          @click="iosToast = false"
        >
          确定
        </button>
      </span>
    </el-dialog>
    <el-dialog
      title="温馨提示"
      :visible.sync="noticeDialog"
      :width="dialogWidth"
      center
    >
      <span
        >Cokejogo演示版本内置的游戏均为仿真实环境开发的单机电子游戏，仅供测试使用，演示版本不提供充值功能，游戏内实际无需上分。</span
      >
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AndroidToast: false,
      iosToast: false,
      dialogWidth: "30%",
      noticeDialog: true,
    };
  },
  methods: {
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = "30%";
      }
    },
  },
  created() {
    this.screeneeWidth();
  },
};
</script>

<style>
.cokeBanner {
  background-image: url("@/assets/banner1.png");
  background-size: 115%;
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.bannerTitle1 {
  font-size: 40px;
  margin: 5px;
  margin-top: 70px;
}

.bannerTitle2 {
  font-size: 30px;
  color: #7ec1ff;
  margin: 0;
  font-weight: normal;
}

.createGame {
  background-color: #4c55d8;
  font-size: 20px;
  color: white;
  border: 0px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.2s ease;
}

.createGame:hover {
  background-color: #24285e;
  color: white;
}

.cokeBtn {
  font-size: 20px;
  font-weight: bolder;
  background-color: #4c55d8;
  color: white;
  padding: 20px 0;
  transition: all 0.2s ease;
  border-radius: 10px;
}

.cokeBtn:hover {
  color: black;
  background-color: white;
}

.mainArea {
  margin: 0 150px;
  text-align: center;
}

.mainImg {
  width: 100%;
  height: 550px;
}

@media screen and (min-width: 1920px) {
  .mainArea {
    margin: 0 350px;
  }
}

@media screen and (max-width: 768px) {
  .bannerTitle1 {
    margin-top: 30px;
    font-size: 20px;
  }
  .bannerTitle2 {
    font-size: 10px;
  }
  .createGame {
    font-size: 10px;
    padding: 5px 10px;
    margin-top: 10px;
  }
  .cokeBtn {
    font-size: 15px;
    padding: 5px;
    margin-top: 10px;
  }
  .cokeBanner {
    height: auto;
    padding-bottom: 20px;
  }
  .mainArea {
    margin: 0 20px;
  }
  .mainImg {
    width: 100%;
    height: 250px;
  }
}
</style>
