<template>
  <div>
    <!-- banner-->
    <div class="banner">
      <el-row :gutter="20">
        <el-col :span="15" :offset="0" style="text-align: center">
          <div class="bannerBtn">
            <img src="@/assets/yono_logo.png" alt="" class="yonologo" />
            <p class="banner_text1">YonoGames海外竖版</p>
          </div>
          <p class="banner_text2">极具性价比，海外棋牌市场爆款产品</p>
          <div style="display: flex; justify-content: left">
            <a href="#/create" style="text-decoration: none">
              <button class="createBtn">+创建我的游戏</button>
            </a>
            <button
              class="createBtn"
              style="margin-left: 10px"
              @click="$router.push('/yonodoc')"
            >
              产品文档
            </button>
            <button class="createBtn" style="margin-left: 10px">
              常见问题
            </button>
          </div>
        </el-col>
        <el-col :span="9" :offset="0"> &nbsp; </el-col>
      </el-row>
    </div>
    <div class="main">
      <!-- 按钮部分 -->
      <el-row :gutter="20" class="playGame">
        <el-col :xs="12" :lg="6" :offset="0">
          <a
            href="http://yono.h5.jisuqipai.online/"
            target="_blank"
            style="text-decoration: none"
          >
            <div class="gameBtn">H5试玩</div>
          </a>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="gameBtn" @click="AndroidToast = true">
            下载Android客户端
          </div>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="gameBtn" @click="iosToast = true">下载iOS客户端</div>
        </el-col>
        <el-col :xs="12" :lg="6" :offset="0">
          <div class="gameBtn" @click="openUpScore()">体验上分</div>
        </el-col>
      </el-row>
      <div class="news">
        <!-- news1 -->
        <div>
          <!-- <p style="font-size: 30px; font-weight: bolder; margin-bottom: 0">
          全新Cocos-Creator引擎，TS语言开发
        </p>
        <div class="gradient-border"></div>
        <p style="font-size: 25px; margin: 0">一套代码，一个终端</p>
        <p style="font-size: 25px; margin-top: 0">平台数据互动，降低运营成本</p>
        <div style="text-align: center">
          <img
            src="@/assets/item.png"
            alt=""
            style="width: 600px; height: 300px"
          />
        </div> -->
          <img src="@/assets/3.png" alt="" class="news_img" />
        </div>
        <!-- news2 -->
        <div>
          <!-- <p style="font-size: 30px; font-weight: bolder; margin-bottom: 0">
          多语种游戏
        </p>
        <div class="gradient-border"></div>
        <p style="font-size: 25px; margin: 0">内置多语言，自由切换</p>
        <div style="text-align: center">
          <img
            src="@/assets/item.png"
            alt=""
            style="width: 600px; height: 300px"
          />
        </div> -->
          <img src="@/assets/4.png" alt="" class="news_img" />
        </div>
        <!-- news3 -->
        <div>
          <!-- <p style="font-size: 30px; font-weight: bolder; margin-bottom: 0">
          精美界面，增强交互
        </p>
        <div class="gradient-border"></div>
        <p style="font-size: 25px; margin: 0">全球通用风格，拓展海外市场</p>
        <div style="text-align: center">
          <img
            src="@/assets/item.png"
            alt=""
            style="width: 600px; height: 300px"
          />
        </div> -->
          <img src="@/assets/5.png" alt="" class="news_img" />
        </div>
        <!-- news4 -->
        <div>
          <img src="@/assets/1.png" alt="" class="news_img" />
        </div>
        <!-- news5 -->
        <div>
          <img src="@/assets/2.png" alt="" class="news_img" />
        </div>
      </div>
    </div>
    <!-- 上分对话框 -->
    <el-dialog
      title="体验上分"
      :visible.sync="upscoreDialogVisible"
      :width="dialogWidth"
      center
    >
      <el-form label-width="80px">
        <el-form-item label="UID">
          <input type="text" v-model="uid" class="scoreInput" />
        </el-form-item>
        <el-form-item label="上分数量">
          <input type="text" v-model="score" class="scoreInput" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn"
          style="
            border: 0px;
            font-size: 15px;
            background-color: black;
            color: white;
          "
          @click="upscoreDialogVisible = false"
        >
          取消
        </button>
        <button
          class="btn"
          style="border: 0px; font-size: 15px; margin-left: 20px"
          @click="YonoUpscores()"
        >
          确定
        </button>
      </span>
    </el-dialog>
    <!-- ios提示 -->
    <el-dialog
      title="提示"
      :visible.sync="iosToast"
      :width="dialogWidth"
      center
    >
      <p>抱歉，演示客户端暂不支持iOS</p>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn"
          style="
            border: 0px;
            font-size: 15px;
            background-color: black;
            color: white;
          "
          @click="iosToast = false"
        >
          取消
        </button>
        <button
          class="btn"
          style="border: 0px; font-size: 15px; margin-left: 20px"
          @click="iosToast = false"
        >
          确定
        </button>
      </span>
    </el-dialog>
    <!-- Android提示 -->
    <el-dialog
      title="Android客户端下载"
      :visible.sync="AndroidToast"
      :width="dialogWidth"
      center
    >
      <div style="text-align: center">
        <img
          src="@/assets/download.png"
          alt="downloadqrcode"
          style="width: 200px; height: 200px"
        />
        <p>打开手机浏览器扫一扫，下载体验YonoGames极速演示版</p>
        <a
          href="https://www.jisuqipai.online/Download/YonoDemo.apk"
          style="text-decoration: none"
          target="_blank"
        >
          <button
            class="btn"
            style="border: 0px; cursor: pointer; font-size: 17px"
          >
            下载Android客户端
          </button>
        </a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { YonoUpscore } from "@/api/api";

export default {
  data() {
    return {
      upscoreDialogVisible: false,
      iosToast: false,
      AndroidToast: false,
      uid: "",
      score: "",
      dialogWidth: "30%",
    };
  },
  methods: {
    YonoUpscores() {
      const loading = this.$loading({
        lock: true,
        text: "正在全力加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      YonoUpscore(this.uid, this.score, token)
        .then((res) => {
          if (res.data.data === 200) {
            this.$message.success("体验上分成功！");
            this.upscoreDialogVisible = false;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    openUpScore() {
      const islogin = localStorage.getItem("isLogin");
      if (
        islogin === false ||
        islogin === null ||
        islogin === "false" ||
        islogin === undefined
      ) {
        this.$message.error("请先登录！");
      } else {
        this.upscoreDialogVisible = true;
      }
    },
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = "30%";
      }
    },
  },
  created() {
    this.screeneeWidth();
  },
};
</script>

<style>
.banner {
  background-image: url("@/assets/inter_banner.png");
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: 130%;
  overflow: hidden;
}

.gameBtn {
  font-size: 20px;
  font-weight: bolder;
  background-color: #cb1414;
  color: white;
  padding: 20px 0;
  transition: all 0.2s ease;
}

.gameBtn:hover {
  color: white;
  background-color: black;
}

.scoreInput {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0px;
  border-bottom: 0.5px #666666 solid;
  height: 30px;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  transition: all 0.3s ease;
  outline: none;
}

.gradient-border {
  position: relative;
  height: 3px;
  background-color: black; /* 左边的黑色实线 */
}

.gradient-border::after {
  content: "";
  position: absolute;
  top: 0;
  /* right: 100%; 将伪元素放置在原元素的右侧 */
  width: 100%; /* 控制渐变的长度 */
  height: 3px;
  background: linear-gradient(to right, black, white); /* 渐变从黑色到白色 */
}

.createBtn {
  background-color: #cb1414;
  color: white;
  display: inline;
  padding: 5px 20px;
  text-align: center;
  border-radius: 2px;
  cursor: default;
  transition: all 0.2s ease;
  font-size: 25px;
  border: 0;
  padding: 10px 30px;
  margin-top: 30px;
  margin-left: 120px;
}

.createBtn:hover {
  background-color: black;
  color: white;
}

.main {
  overflow: hidden;
}

.banner_text1 {
  font-size: 45px;
  font-weight: bolder;
  margin: 70px 0 0 0;
}
.banner_text2 {
  font-size: 40px;
  color: #a73f1d;
  margin: 0;
}
.playGame {
  text-align: center;
}
.news {
  margin-top: 10px;
}
.news_img {
  height: 550px;
  width: 100%;
}
.yonologo {
  height: 50px;
  width: 50px;
  margin: 75px 20px 0 0;
}
.bannerBtn {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .main {
    padding: 0;
  }
  .banner_text1 {
    margin-top: 20px;
    font-size: 20px;
  }
  .banner_text2 {
    font-size: 15px;
  }
  .createBtn {
    font-size: 10px;
    padding: 2px;
    margin-top: 10px;
    margin-left: 30px;
  }
  .banner {
    height: 160px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .gameBtn {
    font-size: 10px;
    padding: 5px;
    margin-top: 10px;
  }
  .news_img {
    height: 200px;
  }
  .yonologo {
    margin: 15px 10px 0 10px;
    width: 30px;
    height: 30px;
  }
  .bannerBtn {
    width: 100%;
  }
}
</style>
